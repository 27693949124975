<template>
  <div class="main">
    <CommonHeader/>
    <div class="title_section">
      <el-link type="primary" @click="back"><i class="el-icon-back"></i>戻る</el-link><el-divider direction="vertical"></el-divider>伝票ロボット設定
    </div>
    <el-tabs type="border-card" style="width: 1060px;margin-top: 36px;border: none;">
      <el-tab-pane label="ロボット設定">
        <div class="config_section">
          <div style="width: 100%;">
            <el-form ref="docImpForm" :model="docImpForm" label-width="150px" :rules="docImpRules" label-position="left" >
              <el-form-item label="実行時間" :required="true">
                <el-col :span="6">
                  <el-form-item prop="starttime">
                    <el-time-select
                      v-model="docImpForm.starttime"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '23:59'
                      }"
                      placeholder="開始時間" style="width: 200px;">
                    </el-time-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="endtime">
                    <el-time-select
                      v-model="docImpForm.endtime"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '23:59'
                      }"
                      placeholder="終了時間" style="width: 200px;">
                    </el-time-select>
                  </el-form-item>
                </el-col>
                
              </el-form-item>
              <el-form-item label="実行頻度" prop="interval">
                <el-select v-model="docImpForm.interval" placeholder="頻度を選択" style="width: 200px;">
                  <el-option
                    label="5分"
                    value="5">
                  </el-option>
                  <el-option
                    label="10分"
                    value="10">
                  </el-option>
                  <el-option
                    label="1時間"
                    value="60">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="インポート方式" prop="channel">
                <el-radio-group v-model="docImpForm.channel">
                  <el-radio label="1">メール</el-radio>
                  <!--el-radio label="2">Googleドライブ</el-radio-->
                  <el-radio label="3">スキャナ
                  </el-radio>
                </el-radio-group>
                &nbsp;
                <el-popover
                  placement="top-start"
                  title="スキャナ"
                  width="200"
                  trigger="hover"
                  content="EPSON指定のスキャナを利用する場合、スキャンファイルを自動的にEnWorkspaceに転送できます。">
                  <i class="el-icon-info" slot="reference"></i>
                </el-popover>
                
              </el-form-item>
              <el-form-item label="アカウント" prop="account">
                <el-select v-model="docImpForm.account" placeholder="アカウントを選択">
                  <el-option
                    v-for="item in accountList"
                    :key="item.id"
                    :label="item.account"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="ファイルパス" prop="path">
                <el-input v-model="docImpForm.path" maxlength="50" style="width: 70%;"></el-input>
              </el-form-item>
              <el-form-item label="キーワード" prop="keyword">
                <el-input v-model="docImpForm.keyword" maxlength="50" style="width: 70%;"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>

      </el-tab-pane>
      <el-tab-pane label="テンプレート設定">
        <div style="padding: 18px;">

        <el-upload
            ref="upload"
            :action="uploadAction"
            :headers="{Authorization:`Bearer ${token}`}"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleUploadOK"
            :on-error="handleUploadError"
            :on-change="handleOnchange"
            :file-list="fileList"
            :auto-upload="true"
            :multiple="false"
            :limit="1"
            :on-exceed="handleExceed"
            :show-file-list="false"
            :on-progress="handleOnprogress"
            >
          <el-button  slot="trigger" size="small" type="primary" plain>ファイル選択</el-button>
        </el-upload>

        <div style="width: 100%;display:flex;align-items: flex-start;margin-top: 18px;">
          <ProcessImage
            :checkedItem="checkedItem"
            :itemList="itemList"
            :imgURI="imgURI"
            :setCheckedItem="setCheckedItem"
          />

          <div style="width: 460px;height:692px;padding: 18px;border: solid 1px #eee;">
            <el-form ref="itemForm" :model="itemForm" label-width="150px" label-position="top" size="small" :rules="itemRules">
              
              <el-form-item label="Kintoneアプリ" prop="appID">
                <el-select 
                  v-model="itemForm.appID" 
                  placeholder="Kintoneアプリを選択" 
                  style="width: 300px;"
                  filterable
                  remote
                  reserve-keyword
                  :remote-method="handleSearch"
                  :loading="loading"
                  @change="handleAppChange">
                  <el-option
                    v-for="(item, index) in this.kappList"
                    :key="index"
                    :label="item.name"
                    :value="item.appId">
                  </el-option>
                </el-select>
                
              </el-form-item>

              <el-form-item label="Kintoneフィールド" prop="itemID">
                <el-select v-model="itemForm.itemID" placeholder="Kintoneフィールドを選択" style="width: 300px;" >
                  <el-option
                    v-for="(field) in this.kitemList"
                    :key="field.code"
                    :label="field.name"
                    :value="field.code">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="スペース" prop="trim">
                <el-radio v-model="itemForm.trim" label="0">削除しない</el-radio>
                <el-radio v-model="itemForm.trim" label="1">削除する</el-radio>
              </el-form-item>

              <el-form-item label="分割" prop="split">
                <el-radio v-model="itemForm.split" label="0">処理しない</el-radio>
                <el-radio v-model="itemForm.split" label="1">「：」左側内容</el-radio>
                <el-radio v-model="itemForm.split" label="2">「：」右側内容</el-radio>
              </el-form-item>

              <el-form-item label="抽出" prop="extract">
                <el-radio v-model="itemForm.extract" label="0">抽出しない</el-radio>
                <el-radio v-model="itemForm.extract" label="1">数字</el-radio>
                <el-radio v-model="itemForm.extract" label="2">英字</el-radio>
                <el-radio v-model="itemForm.extract" label="3">英数字</el-radio>
              </el-form-item>

              <el-form-item label="繰り返し" prop="loop">
                <el-input v-model="itemForm.loop" placeholder="繰り返し回数を入力" style="width: 300px;"></el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="updateConfig" plain>{{$t('account.update')}}</el-button>
              </el-form-item>
              
            </el-form>
          </div>
        </div>
      </div>

      </el-tab-pane>
    </el-tabs>
    
    <div class="menu_section">
        <el-button type="primary" @click="submitForm('docImpForm')" style="width: 150px;">確定</el-button>
        <el-button @click="back" style="width: 150px;">{{$t('account.cancel')}}</el-button>
    </div>
    <div style="flex:1"></div>
    <CommonFooter/>
  </div>
</template>
<script>
import CommonHeader from "@/components/common/CommonHeader.vue";
import CommonFooter from "@/components/common/CommonFooter.vue";
import ProcessImage from "@/components/kintone/ProcessImage.vue";

import { isValidResponse } from "@/utils/commonUtils";
import { ROUTER_PATH } from "@/constants/common.js";
import store from "@/store";
import { mutationsType } from "@/store/mutations-type.js";
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
import { mapState } from "vuex";
import { getUserInfo, update } from "@/api/account";
import {listApp,listField,saveConfig,getConfig,addRobot} from "@/api/kintone";

const validateTime = function(){
  return (rule, value, callback) => {
    if (!value) {
      console.log("value:" + value);
      callback(new Error("実行時間を選択してください。"));
    } else {
      callback();
    }
  }

};
export default {
  name: "DocList",
  components: {
    CommonHeader,
    CommonFooter,
    ProcessImage
  },
  data: () => {
    return {
      docImpForm:{},
      docExeForm:{},
      accountList:[],
      tab:"robot",
      itemList:[],
      imgURI:"",
      checkedItem: null,
      uploadAction: process.env.VUE_APP_BASE_API + "/kcon/image/parse",
      fileList: [],
      token:localStorage.getItem(LOCAL_STORAGE_KEY.RPA_TOKEN),
      itemForm:{},
      kappList:[],
      kitemList:[],
      temp:[],
      robotID:"",
      robotName:"",
      loading: false,
      docImpRules: {
        starttime: [
          { required: true, message: "実行時間を選択してください。", trigger: "change"  },
        ],
        endtime: [
          { required: true, message: "実行時間を選択してください。", trigger: "change" },
        ],
        interval: [
          { required: true, message: "実行頻度を選択してください。", trigger: "change" },
        ],
        channel: [
          { required: true, message: "インポート方式を選択してください。", trigger: "change" },
        ],
        account: [
          { required: true, message: "アカウントを選択してください。", trigger: "change" },
        ]
      },
    itemRules: {
      // appID: [
      //   { required: true, message: "Kintoneアプリを選択してください。", trigger: "change" },
      // ],
      // itemID: [
      //   { required: true, message: "Kintoneアプリの項目を選択してください。", trigger: "change" },
      // ]
    },
    };
  },
  mounted() {
    this.refreshData();
  },
  created: function () {
  },
  computed: {
    ...mapState(["accountInfo"]),
  },
  methods: {
    handleSearch(query) {
      this.loading = true;
      if (query !== '') {
        listApp({
          appName: query
        }).then(response => {
          this.kappList = response.data;
          this.loading = false;
        });
      } else {
        this.kappList = [];
        this.loading = false;
      }
      
    },
    refreshData() {
      this.robotID = this.$route.params.id;
      getUserInfo({
      }).then(response => {
        this.accountList = response.data.mails;
      });
      getConfig({
        id:this.$route.params.id
      }).then(response => {
        if (response.data.config && response.data.config.excuteConfig && response.data.config.importConfig) {
          this.docImpForm = {...response.data.config.excuteConfig,...response.data.config.importConfig};
        } else {
          return;
        }
        
        this.itemList = response.data.config.template;
        this.robotName = response.data.robotName;
        this.imgURI = response.data.config.imgPath;
      });
      // listApp({
      // }).then(response => {
      //   this.kappList = response.data;
      // });
  
    },
    handleAppChange(val) {
      //this.itemForm.itemID = null;
      //this.itemForm.valueTypeName = ""
      listField({
        appId:val
      }).then(response => {
        this.kitemList = response.data;
        //this.itemForm.itemID = "";
      });
    },
    handleItemChange() {
      //console.log("set item:");
      this.kitemList.map((item) => {
        if (item.code == this.itemForm.itemID) {
          this.itemForm.valueTypeName = item.valueTypeName;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = {id:"",userId:"",config:{excuteConfig:{},importConfig:{},template:[],imgPath:this.imgURI}};
          data.id = this.$route.params.id;
          data.userId = this.accountInfo.id;
          data.config.excuteConfig = {...this.docImpForm};
          data.config.importConfig = {...this.docImpForm};
          data.config.template = this.itemList;
          //console.log(data);
          saveConfig(data).then(response => {
            if(isValidResponse(response)) {
              addRobot({
                id: this.$route.params.id,
                status:1
              }).then(response => {
                this.$message({
                  message: "設定しました",
                  type: "success"
                });
              });
            }
            
            this.back();
          });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.form = { ...this.kintonFormOrigin };
    },
    back() {
      this.$router.push("/kintone/DocList");
    },
    setCheckedItem(rowData) {
      this.checkedItem = rowData;
      this.itemForm = {};
      this.itemList.map((config) => {
        if (this.checkedItem.x1 == config.x1 
          && this.checkedItem.y1 == config.y1 
          && this.checkedItem.x3 == config.x3
          && this.checkedItem.y3 == config.y3
        ) {
          if(config.appID){
            listApp({
              appId: config.appID
            }).then(response => {
              this.kappList = response.data;
              listField({
                appId:config.appID
              }).then(response => {
                this.kitemList = response.data;
              });
              this.itemForm = config;
            });
          } else {
            this.itemForm = config;
          }

          this.itemForm.trim = this.itemForm.split ? this.itemForm.split : "0";
          this.itemForm.split = this.itemForm.split ? this.itemForm.split : "0";
          this.itemForm.extract = this.itemForm.split ? this.itemForm.split : "0";
          this.itemForm.loop = this.itemForm.loop != undefined &&  this.itemForm.loop !="" ? this.itemForm.loop : 0;
          
          return;
        }
      });
    },
    updateConfig() {
      if (!this.itemForm.appID) {
        this.$message({
            message:
            "Kintoneアプリを選択してください。",
            type: "warning",
        });
        return;
      }
      if (!this.itemForm.itemID) {
        this.$message({
            message:
            "Kintoneフィールドを選択してください。",
            type: "warning",
        });
        return;
      }
      this.$refs['itemForm'].validate(valid => {
        if (valid) {
          this.handleItemChange();
          this.itemList.map((config,idx) => {
            if (this.checkedItem.x1 == config.x1 
              && this.checkedItem.y1 == config.y1 
              && this.checkedItem.x3 == config.x3
              && this.checkedItem.y3 == config.y3
            ) {
              //this.temp[idx] = this.itemForm;
              this.itemList[idx].appID = this.itemForm.appID;
              this.itemList[idx].itemID = this.itemForm.itemID;
              this.itemList[idx].trim = this.itemForm.trim;
              this.itemList[idx].split = this.itemForm.split;
              this.itemList[idx].extract = this.itemForm.extract;
              this.itemList[idx].valueTypeName = this.itemForm.valueTypeName;

              this.$message({
                  message:
                  "設定しました",
                  type: "success",
              });
              return;
            }
          });
        } else {
          return false;
        }
      })
    },
    ///////upload//////
    submitUpload() {
        this.$refs.upload.submit();
    },
    handleOnprogress(event, file, fileList){
      store.commit(mutationsType.SHOW_LOADING);
    },
    handleRemove(file, fileList) {
    },
    handlePreview(file) {
    },
    handleOnchange(file, flist){
    },
    handleUploadOK(response, file, fileList) {
        // this.$message({
        //     message:
        //     "上传成功",
        //     type: "success",
        // });
        this.fileList = [];
        this.imgURI=response.data.fileUrl;
        this.itemList=response.data.ocrs;
        //this.temp=response.data.ocrs;
        store.commit(mutationsType.HIDE_LOADING);
    },
    handleUploadError(err, file, fileList) {
      store.commit(mutationsType.HIDE_LOADING);
        this.$message({
            message:
            err,
            type: "error",
        });
    },
    handleExceed(files, fileList) {
        store.commit(mutationsType.HIDE_LOADING);
        this.$message({
            message:
            "ファイルは１個のみアップロードできます",
            type: "error",
        });
    },
    
  },
};
</script>
<style scoped>
.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.title_section {
  display: flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  width: 1060px;
  margin-top: 32px;
  font-size: 18px;
  font-weight: bold;
}
.config_section {
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  justify-content:flex-start;
  background-color: #fff;
  width: 1024px;
  padding: 18px 36px;
  border-radius: 12px;
}
.config_title {
  width: 1024px;
  font-size: 18px;
  text-align: left;
  margin-bottom: 36px;
}
.menu_section {
  width: 1012px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  background-color: #fff;
  border-radius: 0px 0px 12px 12px;
  padding: 24px;
}
.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

::v-deep .el-tabs--border-card  {
  background: #FFFFFF;
  border: 0px solid #DCDFE6;
  box-shadow: none !important;
  border-radius: 12px 12px 0px 0px;
  /* -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04); */
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04); */
}

::v-deep .el-tabs__item {
    padding: 0 20px;
    height: 50px !important;;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 50px !important;;
    display: inline-block;
    list-style: none;
    font-size: 16px !important;;
    font-weight: 500;
    color: #303133;
    position: relative;
    border: none !important;
    border-radius: 12px 12px 0px 0px;
}

::v-deep .el-tabs--border-card > .el-tabs__header {
    background-color: #F5F7FA;
    border-bottom: none !important;
    margin: 0;
    border-radius: 12px 12px 0px 0px;
}


</style>

