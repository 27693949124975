<template>
  <div class="process-image-wrapper">
    <div class="image-scrollbar" >
      <el-empty description="ファイルを選択してください" style="margin:auto;with=100%;height: 100%;" v-if="!imgURI"></el-empty>
      <div class="fix-menu" v-show="menuFlg" v-if="imgURI">
        <el-button size="mini" @click="handleFitWidth">横合わせ</el-button>
        &nbsp;
        <el-input-number
          v-model="imageRate"
          :step="5"
          size="mini"
          style="margin-top:10px"
        ></el-input-number>
        &nbsp;
        <el-button size="mini" @click="handleFitHeight">高合わせ</el-button>
      </div>

      

      <div ref="imgbox" class="image" :style="scaleFun" v-if="imgURI">
        
        <!--startprint-->
        <div id="printHtml">
          <img ref="imgsrc" :src="imgURI" />
        </div>
         <!--endprint-->
        <div
          class="rectangle"
          v-for="item in itemWithRectangleList"
          :class="
            [checkedItem && checkedItem.x1 == item.x1 && checkedItem.y1 == item.y1 && checkedItem.x3 == item.x3 && checkedItem.y3 == item.y3 ? 'active' : 'a',
            item.appID?'edited':'']
          "
          :title="item.value"
          :key="item.itemNo"
          :style="item.rectangleStyle"
          @click="setCheckedItem(item)"
        >
        </div>
        
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  name: "ProcessImage",
  props: {
    checkedItem: Object,
    itemList: Array,
    imgURI: String,
    setCheckedItem: Function
  },
  data: () => {
    return {
      imageRate: 100,
      menuFlg: true
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.handleFitWidth();
      }, 3000)
  }) 
    //console.log(window.getComputedStyle(this.$refs.imgbox).height);
  },
  methods: {
    showMenu() {
      this.menuFlg = true;
    },
    hideMenu() {
      this.menuFlg = false;
    },
    handleFitWidth() {
      const wrapper = document.querySelector(
        ".process-image-wrapper .image-scrollbar"
      );
      const img = document.querySelector(
        ".process-image-wrapper .image-scrollbar img"
      );
      const wrapperWidth = wrapper ? wrapper.offsetWidth : 0;
      const imgWidth = img ? img.offsetWidth : 0;
      if (imgWidth > 0) {
        this.imageRate = parseInt((600 / imgWidth) * 100);
      }
      
    },
    handleFitHeight() {
      const wrapper = document.querySelector(
        ".process-image-wrapper .image-scrollbar"
      );
      const img = document.querySelector(
        ".process-image-wrapper .image-scrollbar img"
      );
      const wrapperHeight = wrapper.offsetHeight;
      const imgHeight = img.offsetHeight;
      this.imageRate = parseInt((wrapperHeight / imgHeight) * 100) - 1;
    }
  },
  computed: {
    scaleFun() {
      return `transform:scale(${this.imageRate *
        0.01});transform-origin:0px 0px;width:0px;height:0px`;
    },
    itemWithRectangleList() {
      return this.itemList.map((item) => {
        const width = item.x2 - item.x1;
        const height = item.y4 - item.y1;
        return {
          ...item,
          rectangleStyle: {
            top: `${item.y1}px`,
            left: `${item.x1}px`,
            width: `${width}px`,
            height: `${height}px`,
          },
        };
      });
    },
  } 
};
</script>
<style>
.process-image-wrapper {
  text-align: center;
}
.process-image-wrapper .image-scrollbar {
  width: 600px;
  height: 720px;
  background-color: #F2F6FC;
  overflow: auto;
  margin-top: 0px;
  padding: 5px;
}
.process-image-wrapper .image {
  position: relative;
}
.process-image-wrapper .image .active {
    background-color:transparent;
    opacity: 1;
}
.process-image-wrapper .image .edited {
    background-color:red;
    opacity: 0.2;
}
.process-image-wrapper .rectangle {
  cursor: pointer;
  position: absolute;
  outline-offset: 1px;
  outline: 2px solid red;
  opacity: 0.1;
}
.process-image-wrapper .rectangle:hover {
  background-color:transparent;
  opacity: 1;
}
.hhe .rectangle i{
    position: absolute;
    right:0px;
    bottom:0px;
    color:red;
    font-size: 20px;
    background-color: #fff;
} 
.fix-menu {
  height:50px;
  width:500px;
  z-index:999;
  margin: auto;
}
.dot {
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 2.5px;
  position: relative;
  opacity: 1 !important;
}
</style>
